<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="isGranted" @change="loadPlantSubsidies(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="false">未发放</el-radio-button>
                <el-radio-button :label="true">已发放</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantSubsidies(true)" />
                </div>
            </div>
            <el-table :data="plantSubsidies.data" class="mt-2">
                <el-table-column prop="farmerName" label="种植户" sortable />
                <el-table-column prop="subsidyProgram" label="补贴项目" sortable />
                <el-table-column prop="subsidyAmount" label="补贴金额" sortable />
                <el-table-column prop="subsidyMethod" label="补贴方式" sortable />
                <el-table-column v-if="isGranted!=false" prop="grantDate" label="发放日期" sortable
                    :formatter="$moment.sdFormatter" width="150" />
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantSubsidies.totalRecords"
                :current-page="plantSubsidiesPagination.currentPage" :page-size="plantSubsidiesPagination.pageSize"
                class="mt-3" @current-change="plantSubsidiesPaginationCurrentChange" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isGranted: false,
                key: null,
                plantSubsidies: {},
                plantSubsidiesPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
            };
        },
        methods: {
            async loadPlantSubsidies(resetPage = false) {
                if (resetPage) {
                    this.plantSubsidiesPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantSubsidy/GetPlantSubsidies', {
                    params: {
                        companyId: this.$store.state.companyId,
                        isGranted: this.isGranted,
                        key: this.key,
                        pageIndex: this.plantSubsidiesPagination.currentPage - 1,
                        pageSize: this.plantSubsidiesPagination.pageSize,
                    }
                });
                this.plantSubsidies = response.data;
            },
            plantSubsidiesPaginationCurrentChange(page) {
                this.plantSubsidiesPagination.currentPage = page;
                this.loadPlantSubsidies();
            },
        },
        created() {
            this.loadPlantSubsidies();
        },
    };
</script>